<template>


  <div class="container-fluid px-0">
    <div class="row row-cols-1 row-eq-height justify-content-start">
      <template v-for="presentation in presentations" :key="presentation.id">
        <div class="card h-100  border-0 shadow-sm">
          <div class="position-absolute bottom-0 end-0 pe-0 pe-md-2">
            <FavoriteButton :fav-id="'presentations/' + presentation.id" :parent-id="'sessions/' + presentation.sess_id"
                            v-if="presentation.id && presentation.sess_id" is-list="true"></FavoriteButton>
          </div>
          <router-link :to="'/app/presentations/' + presentation.id" class="row g-0 ps-2 presentation-item">
            <div class="col-1  d-flex align-items-start p-0 pt-2 pb-md-1" style="max-width: 70px">
              <div class="row">
                <div class="timeslot col-12">
                  <ion-icon
                      :name="'time-outline'"
                      class="time-icon pe-1"
                  ></ion-icon>
                  <span class="time"
                        v-show="presentation.start_time && presentation.start_time.split('T')[1]"
                  > {{ moment(presentation.start_time).format('HH:mm') }} </span>
                </div>
                <div class="timeslot col-12">
                  <span class="time mx-auto ps-3" v-if="withDate"
                  > {{ moment(presentation.start_time).format('ddd D') }} </span>
                </div>
              </div>
            </div>
            <div class="col-11 ps-4 ps-sm-2 ">
              <div class="card-body py-0 ps-4 pe-4 ps-sm-2 ps-md-2 mt-2 ">
                <p class="card-title py-0 my-0">
                  <span v-if="presentation.code">{{ presentation.code }} - </span>
                  <span class="presentation-title" v-html="presentation.title"></span>
                </p>
                <p class="card-text mb-1 speaker-line">
                  <span class="" v-for="speaker in presentation.speakers"
                        :key="speaker.id">{{ speaker.display_name }} <span
                      v-if="speaker.country">({{ speaker.country }})</span></span>
                </p>
                <div>
                  <p v-show="presentation.sess_start">{{
                      moment(presentation.sess_start).format('dddd, HH:mm')
                    }}{{ moment(presentation.sess_end).format('- HH:mm') }}
                    <span translate="UHR"></span></p>
                  <small v-if="presentation.video_link && presentation.video_link != 'undefined' "
                         style="margin-top:5px"><i class="ion-ios-videocam"
                                                   style="font-size:1.0rem; color:#000"></i><span
                      style="color:#7F7E7E; font-size:0.9rem"> Video available</span></small>
                  <p v-if="presentation.slides" style="padding-left: 5px; background-color: #dbdbdb; color:#000">Slides
                    available in presentation preview</p>
                  <p v-if="presentation.file_id" style="margin: 5px 0; cursor:pointer"
                     @click="handleFile($event,presentation.file_id,presentation.file_name,true)">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/> &nbsp; Vortrags-PDF verfügbar
                  </p>
                  <p v-if="presentation.poster_file_id" style="margin: 5px 0; cursor:pointer"
                     @click="handleFile($event,presentation.poster_file_id,presentation.poster_file_name,true)">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/> &nbsp; Poster-PDF verfügbar
                  </p>

                  <div class="speaker-line small" v-if="presentation.note">Meine Notiz:
                    <em>&quot;{{ presentation.note }}&quot;</em></div>
                </div>
              </div>
              <hr class="py-0 my-0 mx-auto presentation-item-divider">
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {
  IonIcon,modalController
} from "@ionic/vue";
import moment from 'moment';
import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";
import {mapActions, mapGetters} from "vuex";
import PdfModal from "@/views/Industry/PdfModal";


import FavoriteButton from "@/components/favorite/FavoriteButton";

addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {
    IonIcon,
    FavoriteButton
  },
  props: ['presentations', 'showVideo', 'withDate'],
  created() {
    this.moment = moment;
    console.log('presentations')
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    getPDFLink(link, rewrite) {
      let reslink = link;
      if (rewrite) {
        reslink = 'https://fileserver.s3-de-central.profitbricks.com/' + link;//TEST
      }
      return reslink;
    },

    async handleFile(event,file, name) {
      event.preventDefault();
      event.stopPropagation();
      let link = this.getPDFLink(file, true);
      let viewerLink = 'https://pdf.abstractserver.com/viewer/#/' + link;
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: name,
              link: viewerLink
            },
          })


      let trackData = {
        user: this.user,
        item: {name: name, id: file},
        event: 'viewed',
        type: 'document',
        itemId: file,
      }
      this.trackEvent(trackData);
      return this.modal.present();
    },
  },


};
</script>
<style lang="scss">
.presentation-item {
  text-decoration: none;
  color: #000;

  .speaker-line {
    font-size: 0.8rem;
    color: grey !important;

    span:not(:last-child)::after {
      content: ", ";
    }

    span {
      font-size: 0.8rem;
      color: grey;
    }
  }

  .card-title {
    font-size: 0.8rem !important;
  }

  .card-text {
    color: grey;
  }

  .timeslot {
    display: flex;
    align-items: center;
    color: grey;
    white-space: nowrap;

    .time-icon {
      font-size: 0.9rem;
    }

    .time {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  .presentation-item-divider {
    width: 98%;
    color: lightgray
  }

  @media (min-width: 768px) {
    .pdf-modal {
      --width: 80%;
      --height: 80%;
    }
  }


}
</style>
